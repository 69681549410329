import React from 'react'
import styled, { keyframes } from 'styled-components'
import Homecont from './Homecont'
// import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Sliding from './Sliding';
// import Background from "../img/background.png"
import Paperbg from "../img/paperbg.gif"
import Paperbg2 from "../img/paperbg2-1.webp"
import Leftexplain from "../img/homeleft.webp"
import Folderimg from "../img/folderimg.webp"
import Humanimg from "../img/humanimg.webp"
import { Link } from 'react-router-dom'
import { useAuth } from '../AuthContext'


const OnOff = keyframes`

  0%{
    opacity: 0;
  }
  50%{
    opacity: 100%;
  }
  100%{
    opacity: 0;
  }
`

const Scaleupdown = keyframes`
   0% {
      scale: 1;
  }
 50%{
    scale: 1.05;
 }
  100% {
    scale: 1;
  }
`

const HoemWrap = styled.div`
/* outline: 1px dotted red; */
position: relative;
width: 100%;
height: auto ;
overflow: hidden;
margin-bottom: 50px;

.backgroundtop{
  position: relative;
  margin: 0 auto;
  /* width: 1200px; */
  max-width: 1200px;
  height: 600px;
  background-image: url(${Paperbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: 1px solid rgba(0,0,0,0.05);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  border-radius: 0px 0px 10px 10px;
  transition: 0.2s;

  @media screen and (max-width:700px) {
    position: relative;
  margin: 0 auto;
  /* width: 1200px; */
  max-width: 1200px;
  height: 430px;
  background-image: url(${Paperbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: 1px solid rgba(0,0,0,0.05);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  border-radius: 0px 0px 10px 10px;
  }
  @media screen and (max-width:600px) {
    position: relative;
  margin: 0 auto;
  /* width: 1200px; */
  max-width: 1200px;
  height: 380px;
  background-image: url(${Paperbg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  outline: 1px solid rgba(0,0,0,0.05);
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  border-radius: 0px 0px 10px 10px;
  }
}

.explainpart{
  /* outline: 1px dotted red; */
  /* width: 1200px; */
  max-width: 1200px;
  height: 370px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  margin-bottom: 50px;

  @media screen and (max-width:900px) {
    flex-direction: column;
    height: 440px;
  }

  .explainleft{
    /* outline: 1px dotted red; */
    width: 500px;
    /* max-width: 560px; */
    height: 335px;
    background-image: url(${Leftexplain});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 50px;
    /* box-shadow: 3px 3px 15px rgba(0,0,0,0.4); */
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width:900px) {
      display: none;
    }

  }
  .explainmiddle{
    display: none;
    @media screen and (max-width:900px) {
      box-shadow: 3px 3px 15px rgba(0,0,0,0.4);
      width: 100%;
      height: 260px;
      display: block;
      /* outline: 1px dotted red; */
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
      background-image: url(${Paperbg2});
      background-position: center;
      background-size: cover;
      padding-bottom: 30px;

      h2{
        margin-top: 15px;
        font-size: 25px;
        line-height: 50px;
      }
    }
    @media screen and (max-width:530px) {
      box-shadow: 3px 3px 15px rgba(0,0,0,0.4);
      width: 100%;
      height: 250px;
      display: block;
      /* outline: 1px dotted red; */
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
      background-image: url(${Paperbg2});
      background-position: center;
      background-size: cover;
      letter-spacing: -1px;

      h2{
        margin-top: 15px;
        font-size: 22px;
        line-height: 50px;
      }
      p{
        font-size: 14px;
      }
    }
    @media screen and (max-width:430px) {
      box-shadow: 3px 3px 15px rgba(0,0,0,0.4);
      width: 100%;
      height: 250px;
      display: block;
      /* outline: 1px dotted red; */
      background-color: #fff;
      border-radius: 10px;
      text-align: center;
      background-image: url(${Paperbg2});
      background-position: center;
      background-size: cover;
      letter-spacing: -1px;

      h2{
        margin-top: 15px;
        font-size: 20px;
        line-height: 50px;
      }
      p{
        font-size: 13px;
      }
    }
  }
  .explainright{
    /* outline: 1px dotted red; */
    margin: 0 auto;
    max-width: 600px;
    height: 300px;
    display: flex;
    align-items: center; 
    justify-content: space-around;

   
    

    .menubox1{
      position:relative;
      /* outline: 1px dotted red; */
      width: 180px;
      height: 180px;
      background-image: url(${Folderimg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.2s;

      &:hover{
        scale: 1.1;
      }
      
    }
    .menubox2{
      position:relative;
      /* outline: 1px dotted red; */
      width: 180px;
      height: 180px;
      background-image: url(${Folderimg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.2s;

      &:hover{
        scale: 1.1;
      }

    }
    .menubox3{
      position:relative;
      /* outline: 1px dotted red; */
      width: 180px;
      height: 180px;
      background-image: url(${Humanimg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.2s;

      &:hover{
        scale: 1.1;
      }
    }

    @media screen and (max-width:900px) {
      margin-top: 40px;
    
    }
    @media screen and (max-width:500px) {
      margin-top: 40px;

      .linkbox{
       
        div{
          width: 120px;
          height: 120px;
        }
      }
    }

  }

}

.homeimg{
  /* outline: 1px dotted red; */
  margin: 0 auto;
  /* margin-top: 30px; */
  /* background-color: rgba(121,146,222,1); */
  overflow: hidden;
  width: 100%;
  height: 350px;

  .mainimg{
    width: 1200px;
    height: 350px;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    animation: ${Scaleupdown} 5s ease-in-out infinite;
  }
}
.samplewrap{
  width: 100%;
  /* background-color: red; */
}
.samplepart{
  /* outline: 1px dotted red; */
  /* display: flex; */
  width: 1200px;
  height: auto;
  margin: 0 auto;
  position: relative;
  .fish{
    width: 250px;
    position: absolute;
    z-index: -1;
    left: -50px;
    top: 20px;
    transform: rotate(0deg);
  }
  .frog{
    width: 250px;
    position: absolute;
    z-index: -1;
    right: -150px;
    top: 110px;
    transform: rotate(45deg);
  }
  .cat{
    width: 250px;
    position: absolute;
    z-index: -1;
    left: -180px;
    top: 120px;
    transform: rotate(-35deg);
  }
  .chick{
    width: 250px;
    position: absolute;
    z-index: -1;
    right: -170px;
    bottom: -100px;
    transform: rotate(-240deg);
  }
  .flower{
    width: 250px;
    position: absolute;
    z-index: 1;
    left: -100px;
    bottom: -150px;
    transform: rotate(-40deg);
  }

  p{
    /* outline: 1px dotted red; */
    padding: 30px 0;
    text-align: center;
    line-height: 30px;
    height: auto;
    font-weight: 600;
    span{
  
      font-size: 30px;
      font-weight: 700;
    }
  }
  .sampleimg{
    position: relative;
    z-index: 2;
    outline: 1px solid rgba(0,0,0,0.1);
    width: 1200px;
    height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
    box-shadow: 3px 3px 20px rgba(0,0,0,0.5);

  }
}
`

const Home = () => {
  const { currentUser } = useAuth();

  return (
    <>
        {/* <Sliding/> */}
      <HoemWrap>
        <div className='backgroundtop'/>
        <div className='explainpart'>
          <div className='explainleft'/>
          <div className='explainmiddle'>
          <h2>사업자를 위한 저작권 걱정없는 일러스트 판매!</h2>
              <p>스마트톡, 키링, 티셔츠 등 유니크한 굿즈를 만들어보세요!</p><br/>
              <p><span style={{fontSize:'20px',textDecoration:'underline',textUnderlineOffset:'5px'}}>일반구매, 1:1구매로 나눠서 판매합니다.</span><br/><br/>
                일반구매의 경우 여러 업장에서 판매 될 수 있으며,<br/><br/>
                1:1구매 일러스트를 구매 할 경우 일러스트를 독점으로 사용할 수 있습니다.
              </p>
          </div>
          <div className='explainright'>
            <Link className='linkbox' to='/basic'><div className='menubox1'>
               <p style={{position:'absolute',bottom:'0',textAlign:'center',width:'100%',textDecoration:'none',color:'#fff',textShadow:'2px 2px 2px rgba(0,0,0,0.6)'}}>일반구매</p>
              </div></Link>
            <Link className='linkbox' to='/mono'><div className='menubox2'>
            <p style={{position:'absolute',bottom:'0',textAlign:'center',width:'100%',textDecoration:'none',color:'#fff',textShadow:'2px 2px 2px rgba(0,0,0,0.6)'}}>1:1 구매</p>
              </div></Link>
            
         
            {currentUser ? (
                <Link className='linkbox' to={'/login'}><div className='menubox3'>
                   <p style={{position:'absolute',bottom:'0',textAlign:'center',width:'100%',textDecoration:'none',color:'#fff',textShadow:'2px 2px 2px rgba(0,0,0,0.6)'}}>마이페이지</p>
                  </div></Link>
              ) : (
                <Link className='linkbox' to={'/login'}><div className='menubox3'>
                <p style={{position:'absolute',bottom:'0',textAlign:'center',width:'100%',textDecoration:'none',color:'#fff',textShadow:'2px 2px 2px rgba(0,0,0,0.6)',}}>로그인/회원가입</p>
                </div></Link>
              )}
           
          </div>
        </div>
        {/* <Homecont/> */}
      </HoemWrap>
    </>
  )
}

export default Home
