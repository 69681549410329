import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthContext';
import { Navigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import styled from 'styled-components';
import Paperbg2 from '../img/paperbg2.webp'
import Paperbg3 from '../img/paperbg2-1.webp'

const Allwrap = styled.div` 
  margin: 20px auto;
  width: 90%;
  max-width: 1200px;
  min-height: 500px;
  background-color: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  z-index: 8;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  outline: 1px solid rgba(0,0,0,0.1); 

  .topwrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    
    p {
      margin: 0;
      font-size: 16px;
    }

    .logoutbtn {
      border: none;
      height: 30px;
      border-radius: 5px;
      padding: 5px 10px;
      box-shadow: 3px 3px 3px rgba(0,0,0,0.2);
      background-color: #36b5f6;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #2980b9;
      }
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  .divwrap {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px); /* Adjust height for other elements */
    padding: 20px;
    box-sizing: border-box;
    outline: 1px solid rgba(0,0,0,0.2);
    box-shadow: 4px 4px 10px rgba(0,0,0,0.2);

    .inquiryForm {
      margin-bottom: 20px;

      textarea {
        width: 100%;
        height: 100px;
        padding: 10px;
        margin-bottom: 10px;
        resize: none;
        box-sizing: border-box;
      }

      h2{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      button {
        padding: 10px;
        background-color: #36b5f6;
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &:hover {
          background-color: #2980b9;
        }
      }
    }

    .inquiryList {
      flex-grow: 1; /* Takes available space */
      overflow-y: auto; /* Scroll if content exceeds height */
      h2{
        font-size: 22px;
        font-weight: 700;
      }
      
      ul {
        list-style-type: none;
        padding: 0;

        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          padding: 10px;
          border-bottom: 1px solid #ccc;

          .inquiryText {
            flex-grow: 1;
            margin-bottom: 10px;
            white-space: normal;
            word-wrap: break-word;
            word-break: break-all;
          }

          .replies {
            margin-top: 10px;
            background-color: #f9f9f9;
            padding: 10px;
            border-left: 3px solid #36b5f6;
            border-radius: 5px;

            ul {
              list-style-type: none;
              padding: 0;

              li {
                margin-bottom: 5px;

                p {
                  margin: 0;
                  line-height: 1.5;
                }
              }
            }
          }

          .deleteBtn {
            margin-top: 10px;
            width: 80px;
            height: 40px;
            background-color: #e74c3c;
            color: white;
            border: none;
            padding: 5px 10px;
            cursor: pointer;
            align-self: flex-end;
            transition: background-color 0.3s ease;
            &:hover {
              background-color: #c0392b;
            }
          }
        }
      }
    }
  }
`;

const Mypage = ({ style }) => {
    const { currentUser } = useAuth();
    const [message, setMessage] = useState('');
    const [inquiries, setInquiries] = useState([]);
    const [downloadLinks, setDownloadLinks] = useState([]);

    useEffect(() => {
      if (currentUser) {
        const q = query(
          collection(db, 'users', currentUser.uid, 'inquiries'),
          orderBy('timestamp', 'desc')
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const inquiriesList = [];
          querySnapshot.forEach((doc) => {
            inquiriesList.push({ id: doc.id, ...doc.data() });
          });
          setInquiries(inquiriesList);
        });

        return () => unsubscribe();
      }
    }, [currentUser]);

    useEffect(() => {
      if (currentUser) {
        const q = collection(db, 'users', currentUser.uid, 'downloads');
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const links = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setDownloadLinks(links);
        });
  
        return () => unsubscribe();
      }
    }, [currentUser]);

    const handleLogout = async () => {
      try {
        await signOut(auth);
      } catch (error) {
        console.error('로그아웃 실패:', error);
      }
    };

    const handleInquirySubmit = async (e) => {
      e.preventDefault();
      if (message.trim() !== '' && currentUser) {
        try {
          await addDoc(collection(db, 'users', currentUser.uid, 'inquiries'), {
            message,
            timestamp: new Date(),
            replies: []
          });
          setMessage('');
        } catch (error) {
          console.error('문의사항 저장 중 오류 발생:', error);
        }
      }
    };

    const handleInquiryDelete = async (id) => {
      if (window.confirm('정말로 이 문의사항을 삭제하시겠습니까?')) {
        try {
          await deleteDoc(doc(db, 'users', currentUser.uid, 'inquiries', id));
          console.log('문의사항이 삭제되었습니다.');
        } catch (error) {
          console.error('문의사항 삭제 중 오류 발생:', error);
        }
      }
    };

    if (!currentUser) {
      return <Navigate to="/login" />;
    }
    const BasicAllWrap = styled.div`
max-width: 1200px;
height: auto;
margin: 0 auto;
background-color: #fff;
padding-top: 50px;
padding-bottom: 100px;
margin-bottom: 50px;
border-radius: 0px 0px 10px 10px;
box-shadow: 4px 4px 15px rgba(0,0,0,0.4);
background-image: url(${Paperbg3});

`

    return (

      <BasicAllWrap>

   
        <Allwrap style={style}>
          <h1>마이페이지</h1>
          <div className='topwrap'>
            <p><strong>ID:</strong> {currentUser.email}</p>
            <button className="logoutbtn" onClick={handleLogout}>로그아웃</button>
          </div>
          
         {/*  <div className="divwrap">
            <div className="inquiryForm">
              <h2>문의사항 작성</h2>
              <form onSubmit={handleInquirySubmit}>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="문의사항을 입력하세요"
                />
                <button type="submit">제출</button>
              </form>
            </div>
            <div className="inquiryList">
              <h2 style={{borderBottom:'1px solid rgba(0,0,0,0.2)',paddingBottom:'5px'}}> 내 문의사항</h2>
              <ul>
                {inquiries.map((inquiry) => (
                  <li key={inquiry.id}>
                    <span className="inquiryText">
                      {inquiry.message} - {new Date(inquiry.timestamp.toDate()).toLocaleString()}
                    </span>
                    {inquiry.replies && inquiry.replies.length > 0 && (
                      <div className="replies">
                        <h3>답글:</h3>
                        <ul>
                          {inquiry.replies.map((reply, index) => (
                            <li key={index}>
                              <p><strong>관리자:</strong> {reply.replyMessage}</p>
                              <p><strong>시간:</strong> {new Date(reply.timestamp.toDate()).toLocaleString()}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <button
                      className="deleteBtn"
                      onClick={() => handleInquiryDelete(inquiry.id)}
                    >
                      삭제
                    </button>
                  </li>
                ))}
              </ul>
            </div>  
          </div>
 */}
          
          <div className='divwrap' style={{marginTop:'40px'}}>

          <div className='downloadlink' style={{padding:'10px',}}>

            <h2 style={{fontSize:'20px',marginBottom:'10px',borderBottom:'1px solid rgba(0,0,0,0.2)',paddingBottom:'5px'}}>다운로드 링크</h2>
            {downloadLinks.length > 0 ? (
              <ul>
                {downloadLinks.map((link) => (
                  <li key={link.id} style={{display:'flex',lineHeight:'30px'}}>
                    <p>- {link.itemName}</p>
                    <a href={link.link} target="_blank" rel="noopener noreferrer" style={{marginLeft:'20px'}}> 다운로드</a>
                  </li>
                ))}
              </ul>
                ) : (
                  <p>다운로드 가능한 파일이 없습니다.</p>
                )}

          </div>
                </div>
        </Allwrap>

        </BasicAllWrap>
    );
};

export default Mypage;
