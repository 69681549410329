import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail 
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import styled from 'styled-components';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import './Login.scss';
import Paperbg2 from '../img/paperbg2.webp'
import Paperbg3 from '../img/paperbg2-1.webp'

const SMS_API_URL = process.env.REACT_APP_NCLOUD_SMS_API_URL;
const ACCESS_KEY = process.env.REACT_APP_NCLOUD_ACCESS_KEY;
const SECRET_KEY = process.env.REACT_APP_NCLOUD_SECRET_KEY;
const SENDER_PHONE = process.env.REACT_APP_SENDER_PHONE;

const LoginBold = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  .embos {
    background-color: rgba(226,230,237,1);
    box-shadow: 5px 5px 2px rgba(0,0,0,0.15);
    transition: 0.2s;
    color: #000;
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    &:hover {
      scale: 1.02;
    }
  }

  /* @media screen and (max-width: 680px) {
    display: flex !important;
    justify-content: space-around !important;
    margin-top: 20px !important;

    .embos {
      width: 120px !important;
      height: 30px !important;
      background-color: rgba(0,0,0,0.07) !important;
      box-shadow: 5px 5px 2px rgba(0,0,0,0.15) !important;
      transition: 0.2s !important;
      margin: 0 !important;
      border: 1px solid rgba(0,0,0,0.2) !important;
      &:hover {
        scale: 1.02 !important;
      }
    }
  } */
`;

const ForgotPassword = styled.p`
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ForgotId = styled(ForgotPassword)`
  margin-top: 10px;
`;

const PhoneInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    outline: none;
    border-color: #999;
  }
`;

const ModeToggleButton = styled.button`
  background: none;
  border: none;
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  &:hover {
    color: #333;
  }
`;
const BasicAllWrap = styled.div`
max-width: 1200px;
height: auto;
margin: 0 auto;
background-color: #fff;
padding-top: 50px;
padding-bottom: 100px;
margin-bottom: 50px;
border-radius: 0px 0px 10px 10px;
box-shadow: 4px 4px 15px rgba(0,0,0,0.4);
background-image: url(${Paperbg2});

@media screen and (max-width:680px) {
background-image:none;
background-image: url(${Paperbg3});
  
}
`

const generateVerificationCode = () => {
  return Math.floor(100000 + Math.random() * 900000).toString();
};

const Login = ({ style }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isResetMode, setIsResetMode] = useState(false);
  const [isFindIdMode, setIsFindIdMode] = useState(false);
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [actualVerificationCode, setActualVerificationCode] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    else if (name === 'password') setPassword(value);
    else if (name === 'verificationCode') setVerificationCode(value);
  };
  
  

  const handlePhoneNumberChange = (e) => {
    const rawPhone = e.target.value.replace(/\D/g, '');
    let formattedPhone = rawPhone;

    if (rawPhone.length > 3 && rawPhone.length <= 7) {
      formattedPhone = `${rawPhone.slice(0, 3)}-${rawPhone.slice(3)}`;
    } else if (rawPhone.length > 7) {
      formattedPhone = `${rawPhone.slice(0, 3)}-${rawPhone.slice(3, 7)}-${rawPhone.slice(7, 11)}`;
    }
    setPhoneNumber(formattedPhone);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const phoneRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (!phoneRegex.test(phoneNumber)) {
        throw new Error('올바른 전화번호 형식이 아닙니다.');
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        phoneNumber: phoneNumber,
        uid: user.uid,
        createdAt: new Date(),
      });

      alert('회원가입이 완료되었습니다.');
      setIsSignUpMode(false);
      clearInputs();
    } catch (error) {
      setError('아이디는 이메일 형식으로 작성해주세요!');
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      login(userCredential.user);
      alert('로그인 성공');
      navigate('/mypage');
      clearInputs();
    } catch (error) {
      // setError(error.message);
      setError('이메일과 비밀번호를 확인해주세요!');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert('비밀번호 재설정 이메일이 발송되었습니다. 이메일을 확인해주세요.');
      setIsResetMode(false);
      setResetEmail('');
    } catch (error) {
      setError(error.message);
    }
  };

  const sendVerificationCode = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('phoneNumber', '==', phoneNumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('해당 전화번호로 등록된 계정이 없습니다.');
      }

      const code = generateVerificationCode();
      setActualVerificationCode(code);

      const timestamp = Date.now().toString();

      const headers = {
        'Content-Type': 'application/json',
        'x-ncp-apigw-timestamp': timestamp,
        'x-ncp-iam-access-key': ACCESS_KEY,
      };

      const body = {
        type: 'SMS',
        contentType: 'COMM',
        countryCode: '82',
        from: SENDER_PHONE,
        content: `인증번호는 [${code}] 입니다.`,
        messages: [
          {
            to: phoneNumber.replace(/-/g, '')
          }
        ]
      };

      await axios.post(SMS_API_URL, body, { headers });
      
      setIsVerificationSent(true);
      alert('인증번호가 발송되었습니다.');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleFindId = async (e) => {
    e.preventDefault();
    try {
      if (verificationCode !== actualVerificationCode) {
        throw new Error('인증번호가 일치하지 않습니다.');
      }

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('phoneNumber', '==', phoneNumber));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        alert(`찾은 이메일: ${userDoc.data().email}`);
        setIsFindIdMode(false);
        setIsVerificationSent(false);
        setVerificationCode('');
        setPhoneNumber('');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const clearInputs = () => {
    setEmail('');
    setPassword('');
    setPhoneNumber('');
    setError('');
  };

  const toggleResetMode = () => {
    setIsResetMode(!isResetMode);
    setIsFindIdMode(false);
    setIsSignUpMode(false);
    setError('');
  };

  const toggleFindIdMode = () => {
    setIsFindIdMode(!isFindIdMode);
    setIsResetMode(false);
    setIsSignUpMode(false);
    setError('');
    setPhoneNumber('');
    setVerificationCode('');
    setIsVerificationSent(false);
  };

  const toggleSignUpMode = () => {
    setIsSignUpMode(!isSignUpMode);
    setIsResetMode(false);
    setIsFindIdMode(false);
    clearInputs();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const renderLoginForm = () => (
    
    <form onSubmit={handleLogin}>
      <input
        type="text"
        name="email"
        placeholder="이메일"
        className="test"
        id='id'
        autoComplete="off"
        required
        value={email}
        onChange={onChange}
        style={{marginBottom:'10px',fontWeight:'700'}}
      />
      <p className="tip test">
        <span>TIP</span> 가입하신 이메일을 입력하세요!
      </p>
      
      <input
        type="password"
        name="password"
        placeholder="비밀번호"
        id='password'
        className="test"
        autoComplete="off"
        required
        value={password}
        onChange={onChange}
        style={{marginBottom:'10px',fontWeight:'700'}}
      />
      <p className="tip test">
        <span>TIP</span> 비밀번호를 입력하세요!
      </p>

      <ButtonWrapper>
        <input
          className='embos'
          type="submit"
          value="로그인"
        />
      </ButtonWrapper>
    </form>
  );

  const renderSignUpForm = () => (
    <form onSubmit={handleSignUp}>
      <input
        type="text"
        name="email"
        placeholder="이메일"
        id='id'
        className="test"
        autoComplete="off"
        required
        value={email}
        onChange={onChange}
        style={{marginBottom:'10px'}}
      />
      <p className="tip test">
        <span>TIP</span> 이메일 형식으로 입력하세요!
      </p>
      
      <input
        type="password"
        name="password"
        placeholder="비밀번호"
        className="test"
        autoComplete="off"
        id='password'
        required
        value={password}
        onChange={onChange}
        style={{marginBottom:'10px'}}
      />
      <p className="tip test">
        <span>TIP</span> 6자리 이상 숫자로 설정해주세요!
      </p>

      <input
        type="tel"
        name="phoneNumber"
        placeholder="전화번호 (예: 01012345678)"
        className="test"
        required
        id='tel'
        value={phoneNumber}
        onChange={handlePhoneNumberChange}  // 수정된 부분
        style={{marginBottom:'10px'}}
      />
      <p className="tip test">
        <span>TIP</span> 아이디 찾기에 사용될 전화번호를 입력해주세요!
      </p>

      <ButtonWrapper>
        <input
          className='embos'
          type="submit"
          value="회원가입"
        />
      </ButtonWrapper>
    </form>
  );

  const renderResetPasswordForm = () => (
    <form onSubmit={handlePasswordReset}>
      <input
        type="email"
        id="id"
        value={resetEmail}
        onChange={(e) => setResetEmail(e.target.value)}
        placeholder="이메일 주소를 입력하세요"
        required
        className="test"
        style={{marginBottom:'10px'}}
      />
      <p className="tip test">
        <span>TIP</span> 가입했던 이메일을 입력하시면 비밀번호 재설정 링크를 보내드립니다!
      </p>
      <ButtonWrapper>
        <input
          className='embos'
          type="submit"
          value="재설정 메일 발송"
        />
      </ButtonWrapper>
    </form>
  );

  const renderFindIdForm = () => (
    <form onSubmit={handleFindId}>
      <PhoneInput
        type="tel"
        name="phoneNumber"
        value={phoneNumber}
        onChange={handlePhoneNumberChange} // 수정된 부분
        placeholder="전화번호를 입력하세요 (예: 010-1234-5678)"
        required
        className="test"
      />
      <p className="tip test">
        <span>TIP</span> 가입시 등록한 전화번호를 입력해주세요!
      </p>
      {!isVerificationSent ? (
        <ButtonWrapper>
          <button
            type="button"
            className='embos'
            onClick={sendVerificationCode}
          >
            인증번호 받기
          </button>
        </ButtonWrapper>
      ) : (
        <>
          <PhoneInput
            type="text"
            name="verificationCode"
            value={verificationCode}
            onChange={onChange}
            placeholder="인증번호를 입력하세요"
            required
            className="test"
          />
          <p className="tip test">
            <span>TIP</span> 전송된 인증번호를 입력해주세요!
          </p>
          <ButtonWrapper>
            <input
              className='embos'
              type="submit"
              value="아이디 찾기"
            />
          </ButtonWrapper>
        </>
      )}
    </form>
  );

  return (
    <>
    <BasicAllWrap>

  
  
        <div id="allwrap" style={style}>
          <div id="loginbox" className="test">
            <div className="info test">
              <LoginBold>
                {isResetMode ? '비밀번호 재설정' : 
                 isFindIdMode ? '아이디 찾기' : 
                 isSignUpMode ? '회원가입' : '로그인'}
              </LoginBold>
              
              {isResetMode ? (
                <>
                  {renderResetPasswordForm()}
                  <ButtonWrapper>
                    <button
                      className='embos'
                      type="button"
                      onClick={toggleResetMode}
                    >
                      로그인으로 돌아가기
                    </button>
                  </ButtonWrapper>
                </>
              ) : isFindIdMode ? (
                <>
                  {renderFindIdForm()}
                  <ButtonWrapper>
                    <button
                      className='embos'
                      type="button"
                      onClick={toggleFindIdMode}
                    >
                      로그인으로 돌아가기
                    </button>
                  </ButtonWrapper>
                </>
              ) : isSignUpMode ? (
                <>
                  {renderSignUpForm()}
                  <ButtonWrapper>
                    <button
                      className='embos'
                      type="button"
                      onClick={toggleSignUpMode}
                    >
                      로그인으로 돌아가기
                    </button>
                  </ButtonWrapper>
                </>
              ) : (
                <>
                  {renderLoginForm()}
                  <ButtonWrapper>
                    <button
                      className='embos'
                      type="button"
                      onClick={toggleSignUpMode}
                    >
                      회원가입하기
                    </button>
                  </ButtonWrapper>
                  <ForgotPassword onClick={toggleResetMode}>
                    비밀번호를 잊으셨나요?
                  </ForgotPassword>
                  <ForgotId onClick={toggleFindIdMode}>
                    아이디를 잊으셨나요?
                  </ForgotId>
                </>
              )}
              {error && <p style={{ color: 'red',textAlign:'center',marginTop:"20px",}}>{error}</p>}
            </div>
            <div className="btmmenu">
              <p className="copy">Copyright <strong>KSH Corp</strong>. All rights reserved</p>
            </div>
          </div>
        </div>


      </BasicAllWrap>
    </>
  );
};

export default Login;
