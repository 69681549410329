import React from 'react'
import styled from 'styled-components'
// import Birth from './Birth'
import Allimg from './Allimg'


const HomecontWrap = styled.div`
  /* outline: 1px solid rgba(0,0,0,0.15); */  
  max-width: 100%; // 이부분은 카테고리 컴포넌트를 삽입하고 auto로 바꾼다.
  height: auto;
  margin: 0 auto;
  margin-bottom: 200px;
  /* background-color: lightcoral; */
  /* box-shadow: 8px 8px 15px rgba(0,0,0,0.2); */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */

h2{
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}
  
`
const Homecont = () => {
  return (
    <>
      <HomecontWrap>
        <Allimg/>
      </HomecontWrap>
    </>
  )
}

export default Homecont
