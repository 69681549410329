import React, { useState } from 'react';
import imageData from './Imgcomp';
import monoData from './Monocomp';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

// 스타일 컴포넌트 (변경 없음)
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 80px;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  width: 230px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.3);
  background-color: #fff;
  outline: 1px solid rgba(0,0,0,0.15);

  &:hover {
    scale: 1.02;
  }
`;


const SoldOutTag = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: white;
  padding: 5px;
  font-weight: 700;
  border-radius: 5px;
`;

const H2elWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  height: auto;
  padding-bottom: 40px;
  padding-top: 50px;
  /* outline: 1px dotted red; */
`;

const H2El = styled.h2`
  height: 30px;
  font-size: 20px;
  font-weight: 700;
  /* margin-top: 40px; */
  margin-bottom: -40px;
  text-align: center;

  span {
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    margin-top: -20px;
  }
`;

const H2ElTwo = styled.h2`
  height: 30px;
  font-size: 20px;
  font-weight: 700;
  /* margin-top: 100px; */
  margin-bottom: 0px;
  text-align: center;

  span {
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    margin-top: -20px;
  }
`;

const Img = styled.img`
  width: 230px;
  height: 230px;
`;

const Text = styled.p`
  margin-top: 10px;
  font-size: 18px;
  color: #333;
  font-weight: 700;
`;

const PaginationButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  background-color: #ddd;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: -50px;
  cursor: pointer;
  font-weight: 700;
  &:disabled {
    background-color: #f2da00;
    cursor: not-allowed;
  }
`;

const Allimg = () => {
  const navigate = useNavigate();
  const [soldOutItems] = useState([]); // 판매 완료된 이미지 ID

  // 각각의 페이지 상태
  const [currentPageAll, setCurrentPageAll] = useState(1); // 기본 구매 이미지의 현재 페이지
  const [currentPageMono, setCurrentPageMono] = useState(1); // 독점 구매 이미지의 현재 페이지
  const itemsPerPage = 15; // 페이지당 항목 수

  // 기본 이미지와 독점 이미지를 필터링
  const AllImages = imageData.filter((image) => image);
  const MonoImages = monoData.filter((mono) => mono);

  // 기본 구매 이미지 페이지네이션
  const indexOfLastItemAll = currentPageAll * itemsPerPage;
  const indexOfFirstItemAll = indexOfLastItemAll - itemsPerPage;
  const currentAllImages = AllImages.slice(indexOfFirstItemAll, indexOfLastItemAll);
  const totalAllPages = Math.ceil(AllImages.length / itemsPerPage);

  // 독점 구매 이미지 페이지네이션
  const indexOfLastItemMono = currentPageMono * itemsPerPage;
  const indexOfFirstItemMono = indexOfLastItemMono - itemsPerPage;
  const currentMonoImages = MonoImages.slice(indexOfFirstItemMono, indexOfLastItemMono);
  const totalMonoPages = Math.ceil(MonoImages.length / itemsPerPage);

  // 클릭 핸들러
  const handleClick = (id) => {
    navigate(`/detail/${id}`);
  };
  const monohandleClick = (id) => {
    navigate(`/monodetail/${id}`);
  };

  const handleMonoClick = (id) => {
    if (soldOutItems.includes(id)) {
      alert('이 이미지는 판매 완료되었습니다.');
      return;
    }
    navigate(`/monodetail/${id}`);
  };

  // 페이지 변경 핸들러
  const handlePageChangeAll = (pageNumber) => {
    setCurrentPageAll(pageNumber);
  };

  const handlePageChangeMono = (pageNumber) => {
    setCurrentPageMono(pageNumber);
  };

  return (
    <>
      <H2elWrap style={{marginTop:'80px', /* backgroundImage:`url(${Globalimg})`, */backgroundSize:'contain',backgroundPosition:'center'}}>
        <H2El>
          기본구매 가능 이미지
          <br />
          <span>여러 업장에서 사용 가능하도록 중복으로 판매하고 있습니다.</span>
        </H2El>
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '1200px', margin: '0 auto'}}>
          {currentAllImages.map((image) => (
            <ImageWrapper
              key={image.id}
              onClick={() => handleClick(image.id)}
            >
              <Img src={image.src} alt={image.alt} style={{boxShadow:"2px 2px 8px rgba(0,0,0,0.3)"}} />
              <Text>{image.text}</Text>
              <Text style={{paddingBottom:"20px"}}>{image.price}</Text>
            </ImageWrapper>
          ))}
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {/* 기본 구매 이미지 페이지네이션 버튼 */}
          {[...Array(totalAllPages)].map((_, i) => (
            <PaginationButton
              key={i}
              onClick={() => handlePageChangeAll(i + 1)}
              disabled={currentPageAll === i + 1}
            >
              {i + 1}
            </PaginationButton>
          ))}
        </div>
      </H2elWrap>

      <H2elWrap>
        <H2ElTwo>
          1:1판매 일러스트
          <br />
          <span>
            이미지를 독점으로 사용하고 싶은 회원을 위한 이미지입니다.
            <br />
            판매는 1대1로 이루어지며 판매가 완료되면 다른 회원은 구매할 수 없습니다.
          </span>
        </H2ElTwo>
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '1200px', margin: '0 auto' }}>
          {currentMonoImages.map((mono) => (
            <ImageWrapper
            key={mono.id}
            onClick={() => monohandleClick(mono.id)}>
            <Img src={mono.src} alt={mono.alt} style={{boxShadow:"2px 2px 8px rgba(0,0,0,0.3)"}} />
              <Text>{mono.text}</Text>
              <Text style={{paddingBottom:"20px"}}>{mono.price}</Text>
              {soldOutItems.includes(mono.id) && <SoldOutTag>Sold Out</SoldOutTag>}
            </ImageWrapper>
          ))}
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {/* 독점 구매 이미지 페이지네이션 버튼 */}
          {[...Array(totalMonoPages)].map((_, i) => (
            <PaginationButton
              key={i}
              onClick={() => handlePageChangeMono(i + 1)}
              disabled={currentPageMono === i + 1}
            >
              {i + 1}
            </PaginationButton>
          ))}
        </div>
      </H2elWrap>
    </>
  );
};

export default Allimg;
