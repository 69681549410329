import React, { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import Header from './components/Header';
import Home from './components/Home';
import Birth from './components/Basic';
import Footer from './components/Footer';
import Detailpages from './components/Detailpages';
import { AuthProvider, useAuth } from './AuthContext';
import Mypage from './components/Mypage';
import Login from './components/Login';
import MonoDetailpages from './components/MonoDetailpages';
import Mono from './components/Mono';
import Success from './components/Success';

// require('dotenv').config();

function AppContent() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasNavigated, setHasNavigated] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pgToken = searchParams.get("pg_token");
    const itemName = searchParams.get("itemName");

    if (pgToken && !hasNavigated) {
      setHasNavigated(true);
      navigate(`/success?pg_token=${pgToken}&itemName=${itemName}`);
    }
  }, [location.search, navigate, hasNavigated]);

  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/basic' element={<Birth />} />
        <Route path='/mono' element={<Mono />} />
        <Route path='/detail/:id' element={<Detailpages />} />
        <Route path='/monodetail/:id' element={<MonoDetailpages />} />
        <Route path="/login" element={currentUser ? <Navigate to="/mypage" replace /> : <Login />} />
        <Route path="/mypage" element={currentUser ? <Mypage /> : <Navigate to="/login" replace />} />
        <Route path="/success" element={<Success />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
