import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from '../AuthContext'
import Logo from '../img/logo.webp'
import Headimg from "../img/headimg.webp"

const HeaderWrap = styled.div`
  /* outline: 1px dotted red; */
width: 100%;
/* background-color: rgba(0,0,0,0.); */
  .wrapinner{
    background-color: #fff;
    outline: 1px solid rgba(0,0,0,0.1);
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    max-width: 1200px;
  height: auto;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  z-index: 10;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;

  @media screen and (max-width:700px) {
    background-color: #fff;
    outline: 1px solid rgba(0,0,0,0.1);
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    max-width: 1200px;
  height: auto;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  justify-content: center;
  z-index: 10;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  }

  @media screen and (max-width:600px) {
    background-color: #fff;
    outline: 1px solid rgba(0,0,0,0.1);
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    max-width: 1200px;
  height: auto;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  margin-top: 0px !important;
  z-index: 10;
  border-radius: 0px 0px 0px 0px !important;
  overflow: hidden;
  }



  .headimg{
    /* outline: 1px dotted red; */
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    height: 30px;
    background-image: url(${Headimg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media screen and (max-width:800px) {
    background-color: #fff;
    outline: 1px solid rgba(0,0,0,0.1);
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
    max-width: 1200px;
  height: auto;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
  z-index: 10;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;

  .headimg{
    /* outline: 1px dotted red; */
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    height: 30px;
    background-image: url(${Headimg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
  }
  }
  
  }
  .logo{
    /* outline: 1px dotted red; */
    /* border-bottom: 1px solid rgba(0,0,0,0.2); */
    color: #fff;
    font-size: 34px;
    font-weight: 700;
    /* height: 30px; */
    display: flex;
    text-align: center;
    /* line-height: 80px; */

    .logoinner{
      background-image: url(${Logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .topmenu{
    /* outline: 1px dotted red; */
    /* height: 50px; */
    /* border-bottom: 1px solid rgba(0,0,0,0.2); */
    width:auto;
    margin: 0 auto;
    display: flex;
    /* align-items: center; */
    /* gap: 30px; */
    /* justify-content: space-around; */

    .link{
      /* outline: 1px dotted red; */
      width: 45px;
       text-decoration: none; 
       display: block; 
       color:#000;
       margin-left:15px;
       font-size:12px;

      li{
        /* outline: 1px solid rgba(0,0,0,0.2); */
        /* box-shadow: 2px 2px 3px rgba(0,0,0,0.2); */
        cursor: pointer;
        /* border-radius: 5px; */
        display: block;
        text-decoration: none;
        /* flex: 1; */
        line-height: 30px;
        font-weight: 700;
        text-align: center;
        height: 30px;
        transition: 0.2s;
        
      }
    }
    }
`
  

const Header = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <HeaderWrap>
        <div className='wrapinner'>

        <div className='logo'>
        <Link  to="/" style={{ textDecoration: 'none', display: 'block', color:'#000',marginLeft:'20px'}}>
        <div className='logoinner' style={{/* outline:'1px dotted red', */ width:'150px',height:'30px', margin:'0 auto',marginRight:'0px'}}>{/* GUMIGOMI_ILLUST */}</div>
        </Link>
        
          <ul className='topmenu'>
            <Link className='link' to="/basic"><li>일반구매</li></Link>
            <Link className='link' to='/mono'><li>1:1 구매</li></Link>
            
          </ul>

          {currentUser ? (
                <div style={{right:'0px',top:'0px',width:'100px', height:'30px',display:"flex", alignItems:'center',marginLeft:'20px'}}><Link to={'/login'} style={{ textDecoration: 'none',color:'#000',fontSize:'12px'}}>마이페이지</Link></div>
              ) : (
                <div style={{right:'0px',top:'0px',width:"100px", height:'30px',display:"flex", alignItems:'center',marginLeft:'20px'}}><Link to={'/login'} style={{ textDecoration: 'none',color:'#000',fontSize:'12px'}}>로그인/회원가입</Link></div>
              )}
        </div>

          <div className='headimg'></div>
        </div>
      </HeaderWrap>
    </>
  )
}

export default Header
