const monoData = [
  {
    id: 1,
    category: "mono",
    src: "/img/monocomp/braincoffee/braincoffee.webp",
    src1: "/img/monocomp/braincoffee/braincoffeedet.webp",
    src2: "/img/monocomp/braincoffee/braincoffeedet2.webp",
    alt: "Mono Image 1",
    text: '뇌커피',
    price :'300000원',
  },
  
];

export default monoData;