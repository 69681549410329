const imageData = [
  {
    id: 1,
    category: "basic",
    src: "/img/imgcomp/fishman/fishman.webp",
    src1: "/img/imgcomp/fishman/fishmandet.webp",
    src2: "/img/imgcomp/fishman/fishmandet2.webp",
    alt: "fishman image",
    text: '생선인간',
    price :'130000원',
    gdlink :'https://drive.google.com/file/d/1twyj17HUBJczQv8a4SJVOrHlLXTUGmmS/view?usp=drive_link'
  },
  {
    id: 2,
    category: "basic",
    src: "/img/imgcomp/pokemon/pokemon.webp",
    src1: "/img/imgcomp/pokemon/pokemondet.webp",
    src2: "/img/imgcomp/pokemon/pokemondet2.webp",
    alt: "pokemon illust",
    text: '포켓몬 마스터',
    price :'130000원',
    gdlink:'https://drive.google.com/file/d/1b4zSpQZUsZ8GncdB1WU3_7-w3CuONCjx/view?usp=drive_link'
  },
  
];

export default imageData;